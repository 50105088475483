<template>
  <div class="col-12 mt-3">
    <b-table
        :items="items"
        :fields="fields"
        :busy="isBusy"
        striped
        responsive="sm"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(order_id)="data">
        <a style="cursor: pointer" class="text-left text-warning text-uppercase font-weight-bold" @click="onCopy(data)">{{
            data.item.order_id.substring(data.item.order_id.length - 6)
          }}</a>
      </template>

      <template #cell(gateway)="data">
        <p class="text-left">
          {{ data.item.gateway }}
        </p>
      </template>

      <template #cell(created_at)="data">
        <p v-b-tooltip.hover class="text-left text-wrap">
          {{ getDateInStr(data.item.created_at) }}
        </p>
      </template>

      <template #cell(status)="data">
        <b class="text-left">{{ data.item.status }}</b>
      </template>

      <template #cell(amount)="data">
        <b class="text-left"> ₹ {{ data.item.amount ? data.item.amount : 0 }}</b>
      </template>

    </b-table>
    <b-row>
      <b-col>
        <p>
          Showing {{ this.pageNumber * this.pageSize + 1 }} to
          {{ getEndNumber() }} of {{ this.totalRows }} entries
        </p>
      </b-col>
      <b-col>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="onPageChange"
            align="end"
        >
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import commonUtl from "@/util/commonUtl";
const moment = require("moment");
const axios = require("axios");
export default {
  components: {},
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        {key: "order_id", label: "Order Id"},
        {key: "gateway", label: "Gateway"},
        {key: "amount", label: "Amount"},
        {key: "coupon", label: "Coupon"},
        {key: "status", label: "Status"},
        {key: "payment_mode", label: "Mode"},
        {key: "created_at", label: "Payment At"},
      ],
      pageSize: 10,
      pageNumber: 0,
      currentPage: 1,
      totalRows: 0,
    };
  },
  methods: {
    async onCopy(data) {
      try {
        await navigator.clipboard.writeText(data.item.order_id)
        await commonUtl.showNotification(this, 'success', 'Order Id Copied Successfully')
      } catch (e) {
        await commonUtl.showNotification(this, 'error', 'Not able to copy. Please copy manually')
      }
    },
    getDateInStr(date) {
      if (!date) {
        return "";
      }
      return moment(new Date(date)).format("DD MMM, YYYY HH:mm");
    },
    getReceiptTypes(type) {
      if (!type) {
        return "";
      }
      let val = type.split("_").join(" ");
      val = this.capitalize(val.toLowerCase());
      return val;
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    async onPageChange(pageNumber) {
      this.pageNumber = pageNumber - 1;
      this.email = this.$route.params.email;
      const data = await this.getUserTransactionData(this.email);
      await this.setUserTransactionData(data)

    },
    getEndNumber() {
      const first = this.pageNumber * this.pageSize + this.pageSize;
      return Math.min(this.totalRows, first);
    },

    async getUserTransactionData(email) {
      this.isBusy = true;
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/user/payments?page_size=${this.pageSize}&page_number=${this.pageNumber}&email=${email}`;
        const {data} = await axios.get(url);
        return data || []

      } catch (e) {
        console.log(e);
      } finally {
        this.isBusy = false;
      }
      return []
    },

    async setUserTransactionData(data) {
      this.items = [];
      for (let i = 0; i < data.value.length; i++) {
        this.items.push({...data.value[i], actions: true});
      }
      this.totalRows = data.pagination.total;
    }
  },
  async mounted() {
    this.email = this.$route.params.email
    const data = await this.getUserTransactionData(this.email);
    await this.setUserTransactionData(data)
  },
};
</script>

<style scoped>

</style>