<template>
  <div class="container headMargin">
    <div class="row">
      <div class="col-12 mt-3 text-right">
        <b-button variant="outline-primary"
                  @click="searchUserList">
          Search Users
        </b-button>
      </div>

      <div class="col-12 mt-3">
        <b-tabs nav-class="nav-tabs-custom" content-class="pt-3 text-muted">
          <b-tab active>
            <template v-slot:title>
              <p class="font-weight-bold text-black mb-1">Bills History</p>
            </template>
            <BillsDetailList/>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <p class="font-weight-bold text-black mb-1">Payment History</p>
            </template>
            <TransDetailList/>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <p class="font-weight-bold text-black mb-1">Add Coupon</p>
            </template>
            <AddCoupon/>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import BillsDetailList from '../CustomerDeatils/BiilsDeatils.vue'
import TransDetailList from '../CustomerDeatils/TransactionDetails.vue'
import AddCoupon from '../CustomerDeatils/AddCoupon.vue'


export default {

  components: {BillsDetailList, TransDetailList, AddCoupon},
  data() {
    return {};
  },
  methods: {
    async searchUserList() {
      let url = `/users`
      await this.$router.push(url)
    },
  },
  async mounted() {
  },
};
</script>

<style scoped>
.headMargin {
  margin-top: 100px;
}

.card {
  height: 250px;
  position: relative;
  padding: 25px;
  background: radial-gradient(
      178.94% 106.41% at 26.42% 106.41%,
      #fff7b1 0%,
      rgba(255, 255, 255, 0) 71.88%
  ) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #ffffff;
  border-radius: 23px;
  cursor: pointer;
}

.card-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-header span:first-child {
  word-break: break-all;
  font-weight: 800;
  font-size: 15px;
  line-height: 135%;
  color: #000000;
}

.card-header span:last-child {
  font-weight: 700;
  font-size: 15px;
  line-height: 135%;
  color: #000000;
}

.temp {
  position: absolute;
  left: 25px;
  bottom: 12px;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: rgba(87, 77, 51, 1);
}

.temp-scale {
  width: 80px;
  height: 36px;
  position: absolute;
  right: 25px;
  bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fe930e;
  border-radius: 9px;
}

.temp-scale_btn {
  width: 150px;
  height: 36px;
  position: absolute;
  right: 25px;
  bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fe930e;
  border-radius: 9px;
}

.temp-scale span {
  font-weight: 700;
  font-size: 13px;
  line-height: 134.49%;
  color: #000000;
}

.temp-scale_btn span {
  font-weight: 700;
  font-size: 13px;
  line-height: 134.49%;
  color: #000000;
}
</style>
