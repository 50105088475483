<template>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <b-form-group label="Email Address">
          <b-form-input
              disabled
              v-model="form.email"
              type="email"
              placeholder="Enter Email">
          </b-form-input>
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Coupon">
          <b-form-input
              v-model="form.coupon"
              type="text"
              placeholder="Coupon"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Discount">
          <b-form-input
              v-model="form.discount"
              type="text"
              placeholder="Discount"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group label="Validity">
          <b-form-input
              v-model="form.validity"
              type="text"
              placeholder="Validity"
          >
          </b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 mt-2 text-center justify-content-center">
        <b-button variant="outline-primary"
                  :disabled="loader"
                  @click="addCoupon">
          <b-spinner v-if="loader" small label="Spinning"></b-spinner>
          Add Coupon
        </b-button>
      </div>
    </div>

<!--    <div class="col-12 mt-3">
      <AppliedCouponList/>
    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import commonUtl from "@/util/commonUtl";
//import AppliedCouponList from "@/components/CustomerDeatils/AppliedCouponList.vue";

export default {
  components: {},
  data() {
    return {
      loader: false,
      form: {
        coupon: null,
        email: null,
        discount: 0,
        validity: 0
      }
    }
  },
  methods: {
    async addCoupon() {
      this.loader = true;
      let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/coupon`;
      try {
        const {data} = await axios.post(url, {...this.form});

        if (data.status) {
          //console.log("test", data)
          await commonUtl.showNotification(this, 'success', 'Coupon Code Added Successfully')
          //window.location.reload();
        } else {
          await commonUtl.showNotification(this, 'error', 'Error', data.value)
        }
      } catch (e) {
        const data = e.response.data;
        await commonUtl.showNotification(this, 'error', 'Error', data.value)
      } finally {
        this.loader = false;
      }
    },
  },
  mounted() {
    if (this.$route.params.email) {
      this.form.email = this.$route.params.email;
    }
  }
}
</script>

<style scoped>

</style>