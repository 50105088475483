<template>
  <div class="col-12 mt-3">
    <b-table
        :items="items"
        :fields="fields"
        :busy="isBusy"
        striped
        responsive="sm"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(receipt_type)="data">
        <b class="text-center">{{
            getReceiptTypes(data.item.receipt_type)
          }}</b>
      </template>

      <template #cell(mode)="data">
        <p class="text-center">
          {{ data.item.mode ? data.item.mode : "EMAIL" }}
        </p>
      </template>

      <template #cell(created_at)="data">
        <p v-b-tooltip.hover class="text-left text-wrap">
          {{ getDateInStr(data.item.created_at) }}
        </p>
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
            v-b-tooltip.hover
            title="Download"
            variant="primary"
            @click="downloadItem(data.item)"
        ><i class="bx bx-arrow-to-bottom"></i
        ></b-button>
      </template>

    </b-table>
    <b-row>
      <b-col>
        <p>
          Showing {{ this.pageNumber * this.pageSize + 1 }} to
          {{ getEndNumber() }} of {{ this.totalRows }} entries
        </p>
      </b-col>
      <b-col>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="onPageChange"
            align="end"
        >
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import commonUtl from "@/util/commonUtl";

const moment = require("moment");
const axios = require("axios");
export default {
  components: {},
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        {key: "mode", label: "Mode"},
        {key: "receipt_type", label: "Receipt Type"},
        {key: "template_id", label: "Template Id"},
        {key: "creditsUsed", label: "Credits Used"},
        {key: "filename", label: "File Name"},
        {key: "created_at", label: "Generated At"},
        "actions",
      ],
      pageSize: 10,
      pageNumber: 0,
      currentPage: 1,
      totalRows: 0,
    };
  },
  methods: {
    getDateInStr(date) {
      if (!date) {
        return "";
      }
      return moment(new Date(date)).format("DD MMM, YYYY HH:mm");
    },
    getReceiptTypes(type) {
      if (!type) {
        return "";
      }
      let val = type.split("_").join(" ");
      val = this.capitalize(val.toLowerCase());
      return val;
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    async onPageChange(pageNumber) {
      this.pageNumber = pageNumber - 1;
      this.email = this.$route.params.email
      const data = await this.getUserData(this.email);
      await this.setUserDataGrid(data)
    },
    getEndNumber() {
      const first = this.pageNumber * this.pageSize + this.pageSize;
      return Math.min(this.totalRows, first);
    },

    async getUserData(email) {
      this.isBusy = true;
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/user/bills?page_size=${this.pageSize}&page_number=${this.pageNumber}&email=${email}`;
        const {data} = await axios.get(url);
        return data || []

      } catch (e) {
        console.log(e);
      } finally {
        this.isBusy = false;
      }
      return []
    },

    async setUserDataGrid(data) {
      this.items = [];
      for (let i = 0; i < data.value.length; i++) {
        this.items.push({...data.value[i], actions: true});
      }
      this.totalRows = data.pagination.total;
    },
    async downloadItem(data) {
      this.isBusy = true;
      let endpoint = await commonUtl.getReturnApiEndPoint(data.receipt_type);
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/${endpoint}/${data.id}/download?email=${data.email}`;
        await commonUtl.downloadBill(url, data.filename);
      } catch (e) {
        const data = e.response.data;
        await commonUtl.showNotification(this, "error", "Error", data.value);
      } finally {
        this.isBusy = false;
      }
    },

  },
  async mounted() {
    this.email = this.$route.params.email
    const data = await this.getUserData(this.email);
    await this.setUserDataGrid(data)
  },
};
</script>

<style scoped>

</style>