const axios = require("axios");

async function showNotification(self, type, title, text) {
    self.$notify({
        group: 'foo',
        type: type,
        duration: 7000,
        title: title || 'Success',
        text: text || (type === 'error' ? 'Something Went Wrong. Please try again' : '')
    });
}

async function getReturnApiEndPoint(type) {
    let endpoint = "";
    switch (type) {
        case "ECOM_RECEIPT":
            endpoint = "ecom_receipt";
            break;
        case "BOOK_RECEIPT":
            endpoint = "book_receipt";
            break;
        case "DAILY_HELPER_RECEIPT":
            endpoint = "daily_helper";
            break;
        case "DRIVER_SALARY_RECEIPT":
            endpoint = "driver_salary";
            break;
        case "GENERAL_INVOICE":
            endpoint = "general_receipt";
            break;
        case "INTERNET_INVOICE":
            endpoint = "internet_receipt";
            break;
        case "LTA_RECEIPT":
            endpoint = "lta_receipt";
            break;
        case "MEDICAL_INVOICE":
            endpoint = "medical_bill";
            break;
        case "RECHARGE_BILL":
            endpoint = "recharge_receipt";
            break;
        case "RENT_RECEIPT":
            endpoint = "rent_receipt";
            break;
        case "RESTAURANT_INVOICE":
            endpoint = "rest_receipt";
            break;
        case "STATIONARY_RECEIPT":
            endpoint = "stationary_receipt";
            break;
        case "CAB_BILL":
            endpoint = "cab_bill";
            break;
        case "MART_BILL":
            endpoint = "mart_bill";
            break;
        case "GYM_BILL":
            endpoint = "gym_bill";
            break;
        default:
            endpoint = "fuel_receipt";
            break;
    }
    return endpoint;
}


async function downloadBill(url, filename) {
    // eslint-disable-next-line no-useless-catch
    try {
        const axiosConfig = {
            responseType: "blob",
        };
        const { data } = await axios.post(url, {}, axiosConfig);
        await this.downloadPdfFiles(data, filename);
    } catch (error) {
        throw error;
    }
}

async function downloadPdfFiles(data, fileName) {
    const href = URL.createObjectURL(data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName + ".pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

module.exports = {showNotification,getReturnApiEndPoint,downloadBill,downloadPdfFiles}
