<template>
<div>
  <userAccount/>
</div>
</template>

<script>
import userAccount from "@/components/userAccount/index.vue";

export default {
  components: {
    userAccount,
  },
  data() {
    return {}
  },
  computed: {},

  methods: {}
}

</script>


<style scoped>

</style>